import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Product = ({name, image, price, id, altText}) => {
      
    const path = `/product/${id}`
    return (
        <Card>
            <Link to={path}>
            <img src={image} alt={altText}/>
            <Content>
                <h3>{name}</h3>
                <p></p>
                <p>Prezzo: € {price}</p>
            </Content>
            </Link>
        </Card>
    )
}

const Card = styled.div`
    
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 350px;
    border-radius: 5px;

    a{
        text-decoration: none; 
        cursor: pointer;
        color: #171717;
    }

    img{
        border-radius: 5px 5px 0 0;
        object-fit: contain;
        width: 100%;
        height: 400px;    
        transition: 0.3s;


        &:hover{
            transform: scale(1.13)
        }
    }


    @media(max-width:1248px){
        width: 300px;
        img{
            height: 60%;
        }
    }
`

const Content = styled.div`

    padding: 2px 16px;

`
export default Product
