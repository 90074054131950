import React from "react";
import styled from "styled-components";
import images from "./images/fall21.json";
import LazyLoad from "react-lazy-load";
const Winter22 = () => {
  return (
    <Container>
      {images.map((image) => {
        return (
          <LazyLoad key={image.id}>
            <img src={image.url} alt="Collezione Autunno Inverno 2021/2022" />
          </LazyLoad>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 1024px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  img {
    margin-top: 20px;
    width: 300px;
    object-fit: contain;
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 250px;
    }
  }
`;

export default Winter22;
