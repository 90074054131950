import React, { useContext, useEffect } from 'react'
import {ShopContext} from '../../context/shopContext'
import styled from "styled-components"
import Product from './Product'
import Loader from '../Loader/Loader'



const Store = () => {
    const {fetchAllProducts, products, loading, collections, fetchCollections} = useContext(ShopContext)
    
    // useEffect(()=>{
    //     fetchAllProducts();
    //     fetchCollections();
    //     window.scrollTo(0,0)
    //     return ()=>
    // {

    // };
// eslint-disable-next-line react-hooks/exhaustive-deps
    // },[])

    
    console.log(products, collections)
    return (
        // loading ? <Loader />:
       <Container>
            <div><h1>Sito in manutenzione</h1>
            </div>
       </Container>
    )
}

const Container = styled.div`
    
    display: flex;
    justify-content: center;
    height: 90vh;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 100px;
    max-width: 1300px;
    margin: 0 auto;
    overflow:hidden;
    
    @media(max-width:1248px){
        max-width: 700px;
        grid-template-columns: repeat(2, 1fr);
        
    }
    
    @media(max-width:767px){
        max-width: 300px;
        margin: 0 auto;
        grid-template-columns: repeat(1, 1fr);
        
    }
`



export default Store
