import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import copertine from "./images/copertine.json"
const Collection = () => {
    return (

        <Container>
            {
                copertine.map(
                    image=>{
                        return (
                            <Link to={image.to}>
                            <ImageContainer key={image.id}>
                                <img src={image.url} alt={image.description}className="image"></img>
                                <div className="overlay">
                                    <div className="text">{image.description}</div>
                                </div>
                            </ImageContainer>
                            </Link>
                        )
                    }
                )
            } 
        </Container>
    )
}

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: center;
    max-width: 1300px;
    margin: 0 auto;

  
    img{
        width: 300px;
        height: 600px;
        object-fit: contain;
    }

    @media (max-width: 768px){
        display: flex;
        flex-direction: column;
        align-items:center;
        img{
            margin-top: 20px;
            width: 250px;
            height: 300px;
        }
    }
`
const ImageContainer = styled.div`

    position:relative;
    width: 60%;
    margin: 20px auto;
    
    .image {
         display: block;
        width: 100%;
        height: auto;
    }

    &:hover .overlay{
        opacity: 1;
    }

    .overlay{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .5s ease;
        background-color: rgba(0,0,0,0.5);

    .text{
        color: white;
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        }
    }
`
export default Collection
