import React, {useEffect, useContext, useState} from 'react'
import {useParams} from "react-router-dom"
import styled from 'styled-components'
import {ShopContext} from "../../context/shopContext"
import Loader from '../Loader/Loader'
import ImageGallery from "./ImageGallery"

const ProductDetails = () => {
    const [isAdded, setIsAdded] =  useState(false)
    const [loading, setLoading] = useState(true)
    const [productId, setProductId] = useState("")
    
    const button = ["Aggiungi Al Carrello", "Aggiunto al carrello"]
    const {fetchProductWithId, addItemToCart, product} = useContext(ShopContext)
    const valore = "Select"
    let { id } = useParams();
    console.log(product)
    useEffect(
        ()=>{
            fetchProductWithId(id)
            setLoading(!loading)
            return ()=>{
                
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },[fetchProductWithId, id]
        )
    const handleAddToCart = ()=>{

        if(!productId){
            addItemToCart(product.variants[0].id);
            
            
    }else{
            addItemToCart(productId)
            
            
            
    }
        setIsAdded(!isAdded)
        setTimeout(()=>{
            setIsAdded(false)
            console.log(isAdded)
        }, 1000)
        
        
        
        
    }

    // console.log(checkout)
    const handleChange = e => {
        const pId = e.target.value
        setProductId(pId)
        
    }

    
    return (
        !product.images ? <Loader/>: (
       
            <Container> 

            {/* <div className="image-container">
                <img src={product.images[0].src} alt={product.images[0].altText}/> 

            </div> */}

            
                <ImageGallery images={product.images} firstImage={product.variants[0].image}/>
            <Content>
                <h1>{product.title}</h1>
                <p dangerouslySetInnerHTML={{__html: product.descriptionHtml}}></p>
                <label htmlFor="taglie">Seleziona La Taglia:</label><span>
                    
                    <select name="Taglie" id="taglie" defaultChecked={valore} onChange={handleChange} 
                    onLoad={()=>{
                        setProductId(product.variants[0].id)
                    }}>
                        
                    {product.variants.map(variant=>{
                        return (
                            
                            
                            <option value={variant.id} key={variant.id}>{variant.title}</option>
                            )
                        
                    })}
                </select></span>
                
                 <h4>Price: €{product.variants[0].price}</h4>
                
                <CTA 
                   
                isAdded={isAdded} onClick={handleAddToCart}>
                    
                        {
                            isAdded ? button[1] : button[0]
                        } 
                    
                </CTA>
            </Content>
            
        </Container>
        )
        
    )
}




const Container = styled.div`

    display: grid;
    grid-template-columns: 1fr 1fr; 
    align-items: center;
    justify-content: center;
   
    margin: 0 auto;
    max-width: 1300px;
 
    

    @media (max-width: 1024px){

        display: flex;
        flex-direction: column;
        h1{
            font-size: 23px;
            text-align: center;
        }
        img{
            display: block;
            object-fit:contain;
            height: 300px;
            width: 300px;
        }
        p{
            margin: 1em 10px;
        }

    }

    @media (max-width: 768px){
        max-width: 500px;
    }

`

const Content = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    label{
        font-size: 17px;
        font-weight: bold;
    }
    select{
        width:auto;
        height: 30px;
       
    }
    option{
        text-align: center;
    }

`
const CTA = styled.button`

    border:none;
    width: 150px;
    height: 50px;
    font-size: 17px;
    
    font-weight: bold;
    background-color: ${({ isAdded }) => (isAdded ? "#4CAF50" : "#171717")};
    color: white;
    border-radius: 2px;
    transition: background-color .3s ease-in;

    &:focus{
        border: none;
    }
`

export default ProductDetails
