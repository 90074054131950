import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Navbar from "./Components/Navbar/Navbar";
import Home from "./Components/Home/Home";
import Collection from "./Components/Collection/Collection";
import Store from "./Components/Store/Store";
import ShopProvider from "./context/shopContext";
import ProductDetails from "./Components/ProductPage/ProductDetails";
import Cart from "./Components/Cart/Cart";
import Footer from "./Components/Footer/Footer";
import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";

import Summer21 from "./Components/Collection/Summer21";
import winter23 from "./Components/Collection/Winter23";
import Winter22 from "./Components/Collection/Winter22";
import Summer22 from "./Components/Collection/Summer22";
import Summer23 from "./Components/Collection/Summer23";
import SummerCaps from "./Components/Collection/SummerCaps";

function App() {
  return (
    <ShopProvider>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/collections" component={Collection} />
          <Route path="/store" component={Store}></Route>
          <Route path="/product/:id" component={ProductDetails}></Route>
          <Route path="/cart" component={Cart}></Route>
          <Route path="/summer21" component={Summer21}></Route>
          <Route path="/summer23" component={Summer23}></Route>
          <Route path="/fallwinter22" component={Winter22}></Route>
          <Route path="/fallwinter23" component={winter23}></Route>
          <Route path="/summer22capsule" component={Summer22}></Route>
          <Route path="/summer22" component={SummerCaps}></Route>
          <Route path="/about" component={About}></Route>
          <Route path="/contact" component={Contact}></Route>
        </Switch>
        <Footer></Footer>
      </Router>
    </ShopProvider>
  );
}

export default App;
