import React from 'react'
import styled from 'styled-components'
import wap from "../../assets/wap.svg"
const Contact = () => {
    return (
        <Container>
            <h3>Per informazioni su acquisti onLine e per prendere un appuntamento nel nostro atelier. </h3>
            
            <p className="strong">Mobile <span><img src={wap} alt=""/>: </span><span className="light">+39 339/8818290</span> </p>
            <p className="strong">Email me at: <span className="light">contact@ivaniaboni.com / ivaniaboni.ii@gmail.com</span></p>
            <p className="strong">Press:<span className="light">press@ivaniaboni.com </span></p>
            <p className="strong">Other Info:<span className="light">info@ivaniaboni.com </span></p>
            <h1>Atelier</h1>
            <p className="light">Via Giovanni Zenatello 14 / 16</p>
            <p className="light">00168 <span className="strong">Roma</span></p>

        </Container>
    )
}
const Container = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #1f1f1f;
    text-align: center;
    max-width: 1024px;
    margin:0 auto;
    
    img{
        width: 20px;
        height: 20px;
        margin: 0 5px;
    }
    .strong{
        font-weight: bold;
    }
    .light{
        font-weight: normal;
        color: #3a3a3a;
    }
  
`
export default Contact
