import React, { useContext } from 'react'
import styled from 'styled-components'
import {ShopContext} from '../../context/shopContext'
import bin from "../../assets/bin.svg"


const Cart = () => {
    const {checkout, removeItem} = useContext(ShopContext)
    const handleClick = (itemId)=>{
        removeItem(itemId)
    }
    
    if (!checkout.lineItems) return <div >Loading</div>
    console.log(checkout);
    return  <>
        <h1 className="text-center">Your Shopping Cart</h1>
        <Grid>
        <Container>
            {
                checkout.lineItems.length===0 ? <div className="text-center">
                    <h1>Nessun Oggetto Nel Carrello</h1>
                </div>:
            
            
            
                checkout.lineItems.map(
                    product =>{
                        return(
                        <Product className="product" key={product.id} >
                            <img src={product.variant.image.src} alt={product.title}/>
                            {console.log(product)}
                            <div>
                                <h2>{product.title}</h2>
                                <p>Quantity: {product.quantity}</p>
                                <p>Price: €{product.variant.price}</p>
                                <p>Taglia/Colore: {product.variant.title}</p>
                            </div>
                            
                                <img src={bin} className="icon" alt="" 
                                onClick={()=>{
                                    handleClick(product.id)
                                }}/>
                            
                        </Product>
                        )
                    }

                
                )
            }
          
        </Container>

            <TotalContainer>
                <h1>Totale</h1>
                <p>
                    € {checkout.paymentDue}
                </p>

                <a href={checkout.webUrl}>Continua Il Checkout</a>
            </TotalContainer>
        </Grid>
        
    </>
    
}


const Product = styled.li`
    
    box-shadow: 0 4px 3px rgba(0,0,0,0.2);
    display: flex;
    width:70%;
    justify-content: space-between;
    align-items: center;
    img{
        padding: 1em 0;
        
        width: 200px;
        height: 200px;
        object-fit: contain;
    } 

    .icon{
        width:25px;
        height: 25px;
        cursor: pointer;
        padding: 1em;
       
    }
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    img{
        width: 200px;
        height: 200px;
        object-fit: contain;
    } */

    @media(max-width: 768px){
        
        padding: 0;
        width: 100%;

        img{
            width:100px;
            height: 100px;
        }

        h2{
            font-size: 1.2em;
        }

        .icon{
            padding: .4em;
        }

        
    }

`

const Container = styled.ul`
    max-width: 100%;
    padding:0;
    display: flex;
    flex-direction:column;
    align-items: left;
    justify-content: center;
   
    list-style: none;
    @media (max-width: 1024px){
        max-width: 900px;
        
    }
    @media (max-width: 768px){
        max-width: 400px;
       
    }

`
const Grid = styled.div`
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    max-width: 1300px;
    display: grid;
    grid-template-columns: 3fr 1fr;
    
    @media (max-width: 1024px){
        margin: 0 auto;
        max-width: 900px;
        grid-template-columns: 1fr;
        justify-content: center;
        align-items: center;
    }
    @media (max-width: 768px){
        max-width: 525px;
        grid-template-columns: 1fr;
    }
  
`

const TotalContainer = styled.div`
    text-align: center;

    p{
        margin-bottom: 50px;
    }
    a{
        background-color: #171717;
        padding: 1em 1.2em;
        color: white;
        text-decoration: none;
        border-radius: 5px;    
        
    }
`

export default Cart
