import React, { Component } from 'react'
import Client from "shopify-buy"

const ShopContext = React.createContext()

const client = Client.buildClient(
    {
      domain: 'ivan-iaboni.myshopify.com',
      storefrontAccessToken: process.env.REACT_APP_STOREFRONT_KEY
    }
  )

 class ShopProvider extends Component {

    state = {
        products: [],
        product: {},
        checkout: {},
        images: [],
        isCartOpen : false,
        loading: true,
        collections : []
    }

    componentDidMount(){
        this.createCheckout()
    }

    createCheckout = async()=>{
        const checkout = await client.checkout.create();
        
        localStorage.setItem("checkout", checkout.id)
        this.setState({checkout: checkout}
        )
    }

    addItemToCart = async(variantId, cartId)=>{
        const lineItemsToAdd = [{
            variantId,
            quantity: 1,
        }]

        const checkout = await client.checkout.addLineItems(this.state.checkout.id, lineItemsToAdd)
        this.setState({checkout: checkout})
    }
    removeItem = (itemId) =>{
        const lineItemIdsToRemove = [
            itemId
        ]
        client.checkout.removeLineItems(this.state.checkout.id, lineItemIdsToRemove).then(checkout => this.setState({checkout: checkout}))
    }
    fetchAllProducts = async()=>{
        const products = await client.product.fetchAll(250)
        this.setState({products: products})
        this.setState({loading: false})
    }

    fetchProductWithId=async(id)=>{
        const product = await client.product.fetch(id)
        this.setState({product: product})
      
        
    }

    fetchCollections = async() =>{
        const collections = await client.collection.fetchAllWithProducts()
        this.setState({collections: collections})
    }

    fetchImages = async(id)=>{
        const images = await client.product.fetch(id)
        this.setState({images: images})

    }

    fetchCart = async(cartId)=>{
        
         const cart = await(client.checkout.fetch(cartId))
         this.setState({checkout: cart})
        
    }


    closeCart= ()=>{
        this.setState({isCartOpen: false})
    }
 
    openCart = ()=>{
        this.setState({isCartOpen: true})
    }


    render() {
        return (
            <ShopContext.Provider value={{...this.state,
                fetchAllProducts :this.fetchAllProducts,
                fetchProductWithId : this.fetchProductWithId,
                closeCart: this.closeCart,
                openCart: this.openCart,
                addItemToCart: this.addItemToCart,
                removeItem: this.removeItem,
                fetchImages: this.fetchImages,
                fetchCollections : this.fetchCollections,
                
            }} >
                {this.props.children}
            </ShopContext.Provider>
        )
}
}

const ShopConsumer = ShopContext.Consumer

export {ShopConsumer, ShopContext} 
export default ShopProvider;