import React from 'react'
import styled from 'styled-components'
import instaIcon from "../../assets/instaicon.svg"
const Footer = () => {
    return (
        <Container>
            <div className="footer-content">
                <h3>Ivan Iaboni</h3>
                <ul>
                    <li><p>Follow On Instagram</p><a href="https://www.instagram.com/ivaniaboni_madeinitaly/?hl=it"><img src={instaIcon} alt=""/></a></li>
                </ul>
                <div className="footer-bottom">
                    <p>Ivan Iaboni 2021 &copy; P.Iva:02822290603  </p>
                </div>
            </div>
        </Container>
      );
}


const Container = styled.footer`
    
  margin-top: auto;
  background-color: #fafafa;
  border-top: 1px solid  #eaeaea;
  height: auto;
  width: 100vw;
  padding-top: 40px;
  color: #171717;
  .footer-content{
      display: flex;
      align-items:center;
      justify-content:center;
      flex-direction: column;
      text-align: center;
      ul{
          list-style: none;
          margin:0;
              padding:0;
          li{
             img{
                 width: 25px;
                 height: 25px;

             }
          }
      }
      .footer-bottom{
          background-color: #f0f0f0;
          color: #adadad
      }
  }
`

export default Footer
