import React from 'react'
import styled from 'styled-components'
import Logo from "../../assets/ivanLogoNero.jpeg"
const About = () => {
    return (
        <Container>
            <div>
                <img src={Logo} alt="Ivan Iaboni Logo Fondo Scuro"/>    
            </div>       
            <div>
                <p>
                Ivan Iaboni vive circondato da stoffe, fili e materiali raffinati fin dalla sua giovane età.

Decide di seguire la sua passione con studi nell’Accademia Altieri di Roma.

Il suo innato talento viene immediatamente apprezzato già nel 2007 dove le sue prime creazioni appaiono su reti televisive locali.
Vince il primo premio della Manifestazione “Ponte Milvio in moda” ricevendo la premiazione dalla Dott.sa Valeria Mangani Vice Presidente di <strong>ALTA ROMA ALTA MODA</strong>.

Partecipa all’evento “Cisternino in Moda” con la collaborazione degli accessori firmati ReModa, brand dell’attrice italiana Francesca Rettondini.
Vince il final work dell’Accademia Altieri “Una sera d’estate”.
La sua prima collezione con cui inaugura il suo marchio viene presentata ad Alta Roma Alta Moda.

Successivamente si classifica secondo nel Talent Televisivo di moda Mediaset<strong> “FASHION STYLE”</strong> presentato da Chiara Francini, con la collaborazione degli ospiti in giuria: Alessia Marcuzzi, Silvia Toffanin e Cesare Cunaccia.

Soddisfazioni che arrivano anche dai Social Network, dove sul bel noto instagram Silvia Toffanin pubblica una foto con indosso un suo capo.

Dal 2014 il Brand Ivan Iaboni è presentato in vari store Italiani e non.

Molti influencer indossano varie creazioni dello stilista tra cui Giulia De Lellis, Giulia Salemi, Andrea D’amante, Stefania Orlando, Giulia Pauselli , Valentina Persia, Valeria Graci, Benedetta Mazza, Lidia Schillaci, Valerio Scanu, Selvaggia Roma.

E’ lo stilista ufficiale di Giorgia per il videoclip Oronero.

Molte sue creazioni sono presenti in numerosi programmi TV italiani, tra cui Temptation Island, La sai l’ultima, Uomini e donne e Grande Fratello.

Apre nel 2018 il suo primo store monomarca a Roma.

Ad oggi I suoi capi sono presenti in vari store in Italia e all’estero.
                </p>
                </div>  
        </Container>
    )
}

const Container = styled.div`

    display: flex;
    justify-content: space-between;
    align-items:center;
    max-width: 1240px;
    margin: 0 auto;

     img{
            width: 250px;
            height: 250px;
            object-fit: contain;
            margin-left:-30px;
        }
    
    p{
        line-height: 2.1;
    }


    @media(max-width:1024px){
        flex-direction: column-reverse;
        font-size: 20px;
        padding: 1em;
    }
`

export default About
