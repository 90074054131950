import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import styled from 'styled-components'

const ImageGallery = ({images, firstImage}) => {

    
    const [selectedImg, setSelectedImg] = useState(images[0].src)
    useEffect(
        ()=>{
            setSelectedImg(firstImage.src)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },[images]
    )

    
    return (

        <ImageContainer>

           <img src={selectedImg} alt=""/>
           <div className="image-container">
                {images.map((image, index) =>{
                    return(
                    <img key={index} src={image.src} 
                    style={{border: selectedImg === image.src ?"2px solid #171717": ""}} 
                    alt="variante capo" onClick={
                        ()=>{
                            setSelectedImg(image.src)
                        }
                    }/>)
                })}
           </div>
        </ImageContainer>
    )
}

const ImageContainer = styled.div`
    margin:0 auto;
    width: 100%;
    max-width: 540px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
        padding: 0;
        width: 100%;
        height: 500px;
        object-fit: contain;
    }
    .image-container{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
        justify-content: center;
        img{
            width: 100px;
            height: 100px;
            padding: 10px 0;
            object-fit: contain;
        } 
        }
        @media (max-width: 768px ){
            max-width: 500px;
            img{
                height: 300px;
            }
            
            img{

                width: 240px;
                
            }
    }
`

export default ImageGallery
