import React from 'react'
import styled from 'styled-components'


const Loader = () => {
    return (

        <Wrapper>
            <div className="loader">
                
            </div>
        </Wrapper>
    )
}



const Wrapper = styled.div`

    height: 100%;
    width: 100%;
    text-align: center;

`
export default Loader
