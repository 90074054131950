import React, { useState } from "react";
import {Link} from "react-router-dom"
import styled from "styled-components";
import logo from "../../assets/Logo.svg"
import cart from "../../assets/cart.svg"
import {ShopContext} from '../../context/shopContext'
import { useContext } from "react";
const Navbar = () => {

  const [isOpen, setIsOpen] = useState(false);
  const {checkout} = useContext(ShopContext);
  // const items = checkout.lineItems.length || '0'

  
  return (
    <Nav>
      <Logo>
        <img src={logo} alt=""/>
      </Logo>
      <Hamburger onClick={() => setIsOpen(!isOpen)}>
        <span />
        <span />
        <span />
      </Hamburger>
      <Menu isOpen={isOpen}>
        <Link to="/">HOME</Link>
        <Link to="/collections">COLLECTIONS</Link>
        <Link to="/store">SHOP</Link>
        <Link to="/about">ABOUT ME</Link>
        <Link to="/contact">CONTACT</Link>
        <Link to="/cart">
            <div className="cart">
              <img src={cart} alt=""/>
              <span>{checkout.lineItems ? checkout.lineItems.length: '0'}</span>
            </div>
          </Link>
      </Menu>
    </Nav>
  );
};

export default Navbar;



const Nav = styled.div`
a{
    padding: 1rem 2rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: #171717;
  transition: all 0.3s ease-in;
  font-size: 1rem;
  &:hover {
    color: #5c5c5c;
  }
  a{
    text-decoration: none;
  color: #171717;

  &:hover{
      color:#5c5c5c;
  }
  }
  .cart{
    position: relative;
    img{
      width: 25px;
      height: 25px;
    }
    span{
      color: white;
      position: absolute;
      top: -10px;
      right: -10px;
      background-color: #E87979;
      width: 17px;
      height: 17px;
      border-radius: 100%
    }
  }
}
background-color: #fefefe;
z-index: 1;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  
  margin: 0 auto;
  

  @media (max-width: 768px){
      flex-direction: row;
  }

`;

const Logo = styled.a`
  padding: 1rem 0;
  @media (max-width: 768px){
      img{
          width: 150px;
      }
  }
`;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  @media (max-width: 768px) {
    overflow: hidden;
    flex-direction: column;
    max-height: ${({ isOpen }) => (isOpen ? "300px" : "0")};
    transition: max-height 0.3s ease-in;
    width: 100%;
  }
`;

const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;
  span {
    height: 2px;
    width: 25px;
    background: #171717;
    margin-bottom: 4px;
    border-radius: 5px;
  }
  @media (max-width: 768px) {
    display: flex;
  }
`;