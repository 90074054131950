import React from 'react'
import styled from 'styled-components'
import images from "./images.json"
import Image from "./Image"
import { Link } from "react-router-dom"

const Home = () => {

    
    return (

    <div className="text-center">
        
          
        <Container>
            {images.map(
                image=>{
                    return <Image imgSrc={image.imgSrc} name={image.collectionName} key={image.id}/>
                }
            )}
        </Container>
           <Link className="btn-primary" to="/store">
                Scopri Di Più
               </Link>     
     </div>   
    )
}

const Container = styled.div`
    max-width:1024px;
    margin: 0 auto;
    display: grid;
    grid-gap: 3em;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
    padding-top: 2em;
    @media (max-width: 768px){
        display: flex;
        flex-direction: column;
        overflow: hidden;
        max-width: 500px;
    }
`


export default Home
