import React from 'react'
import styled from 'styled-components'

const Image = ({imgSrc}) => {
    return (
       <ImageContainer>
           <img src={imgSrc} alt="img"/>

         

       </ImageContainer> 
    )
}


const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2em;

    img{
        
        object-fit: contain;
        width: 400px;
    }
    
    div{
        text-align: center;
        a{
            color: white;
            background-color: #171717;
            padding: .7em;
            border-radius: 5px;
            text-decoration: none;
        }
    }

    @media (max-width: 768px){
        img{
            width: 500px;
            height: 400px;
        }

        div{
            h1{
                font-size: 20px;
            }
            a{
                padding: .4em;
            }
        }
    }

`
export default Image
